import html from './index.html';
import styles from './index.scss';

/**
 * Renders the country selector modal. The modal renders the required localization form with a
 * single usable drop down listing all available markets as indicated in the Shopify global objects.
 * Once the user selects a country, the disabled currency input will populate the currency ISO code
 * for that country. Once a user saves the form will be submitted and on next page load the country
 * and currency locale will reflect their choice.
 */
class CountrySelectorForm extends HTMLElement {
  public static get observedAttributes() {
    return [
      'data-country-code',
      'data-country-name',
      'data-currency-code',
      'data-language-code'
    ];
  }

  readonly dataset!: {
    /**
     * Country ISO Code. Required.
     */
    countryCode: string;

    /**
     * Country Name. Required.
     */
    countryName: string;

    /**
     * Currency ISO Code. Required.
     */
    currencyCode: string;

    /**
     * Language ISO Code. Required.
     */
    languageCode: string;
  };

  private onDOMContentLoadedBound = this.onDOMContentLoaded.bind(this);
  private onItemClickBound = this.onItemClick.bind(this);
  private onContainerKeyUpBound = this.onContainerKeyUp.bind(this);
  private onButtonClickBound = this.onButtonClick.bind(this);
  private onButtonFocusOutBound = this.onButtonFocusOut.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open', delegatesFocus: true });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      setTimeout(this.onDOMContentLoadedBound);
    } else {
      addEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
    }

    const button = this.shadowRoot.querySelector<HTMLButtonElement>('button.disclosure__button');
    button.addEventListener('click', this.onButtonClickBound);
    button.addEventListener('focusout', this.onButtonFocusOutBound);

    this.addEventListener('keyup', this.onContainerKeyUpBound);
    this.render();
  }

  /**
   * This queries the light dom, which may not be ready by the time of connected callback
   */
  private onDOMContentLoaded(_event: Event) {
    const anchors = this.querySelectorAll('a');
    for (const anchor of anchors) {
      // remove in case this is an additional run
      anchor.removeEventListener('click', this.onItemClickBound);
      anchor.addEventListener('click', this.onItemClickBound);
    }
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  public disconnectedCallback() {
    removeEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);

    const button = this.shadowRoot.querySelector<HTMLButtonElement>('button.disclosure__button');
    if (button) {
      button.removeEventListener('click', this.onButtonClickBound);
      button.removeEventListener('focusout', this.onButtonFocusOutBound);
    }

    this.removeEventListener('keyup', this.onContainerKeyUpBound);

    const anchors = this.querySelectorAll('a');
    for (const anchor of anchors) {
      anchor.removeEventListener('click', this.onItemClickBound);
    }
  }

  private onButtonClick() {
    const button = this.shadowRoot.querySelector<HTMLButtonElement>('button.disclosure__button');
    button.focus();

    const panel = this.querySelector('ul');
    panel.toggleAttribute('hidden');

    if (button.getAttribute('aria-expanded') === 'true') {
      button.setAttribute('aria-expanded', 'false');
    } else {
      button.setAttribute('aria-expanded', 'true');
    }
  }

  private onButtonFocusOut(event: FocusEvent) {
    if (event.relatedTarget === null || event.relatedTarget instanceof HTMLButtonElement) {
      this.hidePanel();
    }
  }

  private hidePanel() {
    const button = this.shadowRoot.querySelector<HTMLButtonElement>('button.disclosure__button');
    button?.setAttribute('aria-expanded', 'false');

    const panel = this.querySelector('ul');
    panel.setAttribute('hidden', 'true');
  }

  private onContainerKeyUp(event: KeyboardEvent) {
    if (event.code.toUpperCase() !== 'ESCAPE') {
      return;
    }

    this.hidePanel();

    const button = this.shadowRoot.querySelector<HTMLButtonElement>('button.disclosure__button');
    button.focus();
  }

  private onItemClick(event: MouseEvent) {
    event.preventDefault();

    this.hidePanel();
    const selected = <HTMLElement>event.currentTarget;

    const buttonSpan = this.shadowRoot.querySelector<HTMLButtonElement>(
      'button.disclosure__button span');
    buttonSpan.textContent = selected.dataset.countryName;

    const input = this.shadowRoot.querySelector<HTMLInputElement>('input[name="country_code"]');
    input.value = selected.dataset.value;

    const currencyInput = this.shadowRoot.querySelector<HTMLInputElement>('input.currency-code');
    currencyInput.value = selected.dataset.currencyCode;

    this.updateSelectedAttribute(selected.parentElement.id);

    const button = this.shadowRoot.querySelector<HTMLButtonElement>('button.disclosure__button');
    button.focus();
  }

  private updateSelectedAttribute(selectedId: string) {
    const options = this.querySelectorAll<HTMLElement>('[slot="language-list"] li');
    for (const option of options) {
      if (option.id === selectedId) {
        option.setAttribute('aria-selected', 'true');
      } else {
        option.setAttribute('aria-selected', 'false');
      }
    }
  }

  private render() {
    const buttonSpan = this.shadowRoot.querySelector<HTMLButtonElement>(
      'button.disclosure__button span');
    buttonSpan.textContent = this.dataset.countryName;

    const countryInput = this.shadowRoot.querySelector<HTMLInputElement>(
      'input[name="country_code"]');
    countryInput.value = this.dataset.countryCode;

    const currencyInput = this.shadowRoot.querySelector<HTMLInputElement>('input.currency-code');
    currencyInput.value = this.dataset.currencyCode;

    const languageInput = this.shadowRoot.querySelector<HTMLInputElement>(
      'input[name="language_code"]');
    languageInput.value = this.dataset.languageCode;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'country-selector-form': CountrySelectorForm;
  }
}

if (!customElements.get('country-selector-form')) {
  customElements.define('country-selector-form', CountrySelectorForm);
}
